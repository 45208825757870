import { useState } from "react";

const handleFormchange = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const [suggestions, setSuggestions] = useState([]);

const handleChange = async (event) => {
  setValue(event.target.value);

  try {
    const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${event.target.value}.json?country=US&types=country,region,place,postcode,locality&access_token=pk.eyJ1IjoidG93amoiLCJhIjoiY2txMnB6ZzliMGFhbzMydDZ0cjcxYnkxaSJ9.gbldZDVPR4I0i8XzZ1RJnw&autocomplete=true`;
    const response = await fetch(endpoint);
    const results = await response.json();
    setSuggestions(results?.features);
  } catch (error) {
    console.log("Error fetching data, ", error);
  }
};

return {
  value,
  onChange: handleChange,
  setValue,
  suggestions,
  setSuggestions
};

};

export default handleFormchange;