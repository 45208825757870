import {useState, React } from "react";
import handleFormchange from "./handlechange";
import { useFormContext } from "react-hook-form"


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export default function address({type}) {
const [isHovering, setIsHovering] = useState(false);
const setType = `${type}`
const address = handleFormchange("");
const { trigger, setValue, register, formState: { errors } } = useFormContext()

 const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <>
    <div
     onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}>

      <input

      {...register(setType, {
        required: true
        })}
      className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-black tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 sm:tw-text-sm sm:tw-leading-6"
      placeholder="City, State or ZIP Code"
      value={address.value}
      onChange={address.onChange}
      autocomplete="chrome-off"
      />
      

         {isHovering && (
         <div>
        {address.suggestions?.length > 0 && (
        <div className="tw-absolute tw-bg-white tw-shadow-lg tw-flex tw-flex-col tw-w-full tw-divide-y tw-z-40">
          {address.suggestions.map((suggestion, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  address.setValue(suggestion.place_name);
                  address.setSuggestions([]);
                  setValue(setType, suggestion.place_name)
                }}
                className="tw-bg-white hover:tw-bg-gray-200 tw-border-b tw-border-gray-300 tw-border-0.5 tw-cursor-pointer"
              >
                <span className="tw-text-md md:tw-text-base tw-text-black tw-px-2 tw-py-0.5 tw-flex tw-items-center tw-gap-x-1"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="tw-w-4 tw-h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
</svg>
{suggestion.place_name.replace(', United States','')}</span>
              </div>
            );
          })}
        </div>
      )}
        </div>
        )}
        
        {/*<div><div onClick={() => handleClick()}>test</div></div>*/}
        </div>
        {errors[setType] && (
                      <span className="tw-text-red-500 tw-font-semibold tw-text-sm">
                        This field is required
                      </span>
                    )}
    </>
   
  );
}
