import { useState, useEffect } from "react";
import {
  useForm,
  FormProvider,
  useFormContext,
  Controller,
} from "react-hook-form";
import Address from "./components/address";

const services = [
  { name: "Birthday" },
  { name: "Prom" },
  { name: "Funeral" },
  { name: "Wedding" },
  { name: "Night-on-the-Town" },
  { name: "Corporate or Group Outing" },
  { name: "Concert" },
  { name: "Sporting Event" },
  { name: "Bachelor Party / Bachelorette" },
  { name: "HomeComing" },
  { name: "General Transportation" },
];

const timeslots = [
  { name: "5:00 AM", value: "05:00" },
  { name: "5:30 AM", value: "05:30" },
  { name: "6:00 AM", value: "06:00" },
  { name: "6:30 AM", value: "06:30" },
  { name: "7:00 AM", value: "07:00" },
  { name: "7:30 AM", value: "07:30" },
  { name: "8:00 AM", value: "08:00" },
  { name: "8:30 AM", value: "08:30" },
  { name: "9:00 AM", value: "09:00" },
  { name: "9:30 AM", value: "09:30" },
  { name: "10:00 AM",  value: "10:00" },
  { name: "10:30 AM",  value: "10:30" },
  { name: "11:00 AM",  value: "11:00" },
  { name: "11:30 AM",  value: "11:30" },
  { name: "NOON",  value: "12:00" },
  { name: "12:30 PM",  value: "12:30" },
  { name: "1:00 PM", value: "13:00" },
  { name: "1:30 PM", value: "13:30" },
  { name: "2:00 PM", value: "14:0" },
  { name: "2:30 PM", value: "14:30" },
  { name: "3:00 PM", value: "15:00" },
  { name: "3:30 PM", value: "15:30" },
  { name: "4:00 PM", value: "16:00" },
  { name: "4:30 PM", value: "16:30" },
  { name: "5:00 PM", value: "17:00" },
  { name: "5:30 PM", value: "17:30" },
  { name: "6:00 PM", value: "18:00" },
  { name: "6:30 PM", value: "18:30" },
  { name: "7:00 PM", value: "19:00" },
  { name: "7:30 PM", value: "19:30" },
  { name: "8:00 PM", value: "20:00" },
  { name: "8:30 PM", value: "20:30" },
  { name: "9:00 PM", value: "21:00" },
  { name: "9:30 PM", value: "21:30" },
  { name: "10:00 PM",  value: "22:00" },
  { name: "10:30 PM",  value: "22:30" },
  { name: "11:00 PM",  value: "23:00" },
  { name: "11:30 PM",  value: "23:30" },
  { name: "MIDNIGHT",  value: "23:59" },
];





const range = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

const currentYear = new Date().getFullYear();
const MoreYears = Array.from(new Array(2), (val, index) => index + currentYear);

const months = Array.from({ length: 12 }, (e, i) => {
  return new Date(null, i + 1, null).getMonth() + 1;
});

const monthsLabel = Array.from({ length: 12 }, (e, i) => {
  return new Date(null, i + 1, null).toLocaleDateString("en", {
    month: "short",
  });
});

const getDaysArray = (function () {
  const names = Object.freeze([
    "sun",
    "mon",
    "tue",
    "wed",
    "thu",
    "fri",
    "sat",
  ]);
  return (year, month) => {
    const monthIndex = month - 1;
    const date = new Date(year, monthIndex, 1);
    const result = [];
    while (date.getMonth() == monthIndex) {
      //result.push(`${date.getDate()}`);
      result.push(`${date.getDate()}`.padStart(2, "0"));
      date.setDate(date.getDate() + 1);
    }
    return result;
  };
})();

const getDayLabel = (function () {
  const names = Object.freeze([
    "sun",
    "mon",
    "tue",
    "wed",
    "thu",
    "fri",
    "sat",
  ]);
  return (year, month) => {
    const monthIndex = month - 1;
    const date = new Date(year, monthIndex, 1);
    const result = [];
    while (date.getMonth() == monthIndex) {
      //result.push(`${date.getDate()}`);
      result.push(`${names[date.getDay()]}`);
      date.setDate(date.getDate() + 1);
    }
    return result;
  };
})();


export function Form() {
  const [svcType, setSvcType] = useState(false);
  const [pax, setPax] = useState(false);

  const [serviceMonth, setServiceMonth] = useState(new Date().getMonth() + 1);
  const [serviceDay, setServiceDay] = useState(
    new Date().toLocaleString("en-US", { day: "2-digit" }),
  );

  const [selectedMonth, setSelectedMonth] = useState("")
  const [selectedDay, setSelectedDay] = useState("")

  const [calcHours, setCalcHours] = useState(false)

  const [serviceYear, setServiceYear] = useState(currentYear);
  const [puTime, setPutime] = useState(false);
  const [hrs, setHrs] = useState(false);
  
  const [disabled, setDisabled] = useState(false);
  const [formComplete, setFormComplete] = useState(false)

  const methods = useForm();

  const {
    setValue,
    trigger,
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

var options = {
  hour: 'numeric',
  minute: 'numeric',
  hour12: true
};

  useEffect(() => {
    register("surl")
      setValue('surl', window.location.href);
  })


  const onSubmit = async (data) => {
    let isValid = false;
    isValid = await trigger([
      "pickup",
      "dropoff",
      
      "service_type",
      "passengers",
      
      "svc_month",
      "svc_day",
      "svc_year",
      
      "pickup_time",
      "hours_needed",

      "email_address",
      
    ]);
    if (isValid) {
      console.log(data);
      setDisabled(true);
      
      const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ reqq: data })
          };
          const response = await fetch('https://app.projects908950.cloud/api/post2', requestOptions);
          const form_data = await response.json();
          console.log(form_data.redir)
          window.scrollTo({top: 0, behavior: 'smooth'})
          window.location.href = 'https://www.price4limo.com/quotes/results.php?'+form_data.redir.replace(/['"]+/g, '');
    }
  };

  return (
    <div className="tw-mx-auto tw-relative">
   

    {formComplete ? (

<div className="tw-px-4 tw-py-5 sm:tw-px-6 tw-flex tw-flex-col tw-justify-center">
    <h3 className="tw-text-md tw-flex tw-flex-col tw-items-center tw-justify-center tw-font-semibold tw-leading-6 tw-text-black">
        <svg className="tw-h-9 tw-w-9 tw-text-green-500 tw-ml-1" viewBox="0 0 24 24" fill="currentColor"aria-hidden="true">
            <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd"></path>
        </svg>
        <span className="tw-text-center tw-text-xl tw-mb-2">Currently processing request details</span>
    </h3>
    <p className="tw-mt-1 tw-text-md tw-text-center tw-text-black">
        A reservation specialist will contact you shortly with pricing and
        availbility in your area
    </p>
</div>

      ) :


      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div>
            <div className="tw-pb-2 tw-relative">
              <div>
                <div className="sm:tw-grid tw-grid-cols-2 tw-gap-x-4 tw-mb-3">
                  <div>
                    <label
                      htmlFor="first-name"
                      className="tw-block tw-text-sm tw-font-medum tw-leading-6 tw-text-black tw-mb-2"
                    >
                      Pick Up City, State:
                    </label>
                    <div className="tw-relative">
                      <Address type="pickup" />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="last-name"
                      className="tw-block tw-text-sm tw-font-medum tw-leading-6 tw-text-black tw-mb-2 tw-mt-3 md:tw-mt-0"
                    >
                      Drop Off City, State:
                    </label>
                    <div className="tw-relative">
                      <Address type="dropoff" />
                    </div>
                  </div>
                </div>

                <div className="sm:tw-grid tw-grid-cols-2 tw-gap-x-4 tw-mb-3">
                  <div>
                    <label
                      htmlFor="service"
                      className="tw-block tw-text-sm tw-font-medum tw-leading-6 tw-text-black tw-mb-2 tw-mt-3 md:tw-mt-0"
                    >
                      Type Of Service:
                    </label>
                    <div>
                      <select
                        {...register("service_type", { required: true })}
                        value={svcType}
                        onChange={(e) => {
                          setValue("service_type", e.target.value);
                          setSvcType(e.target.value);
                        }}
                        id="service"
                        name="service"
                        autocomplete="chrome-off"
                        className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-black tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 sm:tw-text-sm sm:tw-leading-6"
                      >
                        <option value="">Select Service</option>
                        {services?.map((service) => (
                          <option key={service.name} value={service.name}>
                            {service.name}
                          </option>
                        ))}
                      </select>
                      {errors.service_type && (
                        <span className="tw-text-red-500 tw-font-semibold tw-text-sm">
                          This field is required
                        </span>
                      )}
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="passengers"
                      className="tw-block tw-text-sm tw-font-medum tw-leading-6 tw-text-black tw-mb-2 tw-mt-3 md:tw-mt-0"
                    >
                      Passengers:
                    </label>
                    <div>
                      <select
                        {...register("passengers", { required: true })}
                        onChange={(e) => {
                          setValue("passengers", e.target.value);
                          setPax(e.target.value);
                        }}
                        value={pax}
                        id="passengers"
                        name="passengers"
                        autocomplete="chrome-off"
                        className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-black tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 sm:tw-text-sm sm:tw-leading-6"
                      >
                        <option value="">Select Passengers</option>
                        {range(1, 64, +1)?.map((item) => (
                          <option value={item} key={item}>
                            {item}
                          </option>
                        ))}
                        <option value="65">65-100</option>
                        <option value="100">100+</option>
                      </select>
                      {errors.passengers && (
                        <span className="tw-text-red-500 tw-font-semibold tw-text-sm">
                          This field is required
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                  <div className="md:tw-grid tw-grid-cols-2 tw-gap-x-4 tw-mb-3">

                <div className="tw-grid tw-grid-cols-3 tw-gap-x-2 tw-mb-3 md:tw-mb-0">
                  <div className="tw-col-span-3">
                    <label
                      htmlFor="date"
                      className="tw-block tw-text-sm tw-font-medum tw-leading-6 tw-text-black tw-mb-2"
                    >
                      Date of Service:
                    </label>
                  </div>
                  <div>
                    <select
                      {...register("svc_month", { required: true })}
                      onChange={(e) => {
                        setValue("svc_month", e.target.value);
                        setServiceMonth(e.target.value);
                        setSelectedMonth(e.target.value);
                        setServiceDay(false);
                      }}
                      value={selectedMonth}
                      placeholder="Month"
                      id="svc_month"
                      name="svc_month"
                      autocomplete="chrome-off"
                      className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-black tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 sm:tw-text-sm sm:tw-leading-6"
                    >
                      <option value=''>Month</option>
                      {months?.map((month, index) => (
                        <option key={index} value={month}>
                          {monthsLabel[index]}
                        </option>
                      ))}
                    </select>
                    {errors.svc_month && (
                      <span className="tw-text-red-500 tw-font-semibold tw-text-sm tw-mb-2">
                        This field is required
                      </span>
                    )}
                  </div>
                  <div>
                    <select
                      {...register("svc_day", { required: true })}
                      onChange={(e) => {
                        setValue("svc_day", e.target.value);
                        setServiceDay(e.target.value);
                        setSelectedDay(e.target.value);
                      }}
                      value={selectedDay}
                      placeholder="Day"
                      id="svc_day"
                      name="svc_day"
                      autocomplete="chrome-off"
                      className="tw-block tw-w-full tw-capitalize tw-rounded-md tw-border-0 tw-py-1.5 tw-black tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 sm:tw-text-sm sm:tw-leading-6"
                    >
                    <option value=''>Day</option>
                      {getDaysArray(serviceYear, serviceMonth)?.map(
                        (day, index) => (
                          <option key={day} value={day}>
                            {day} -{" "}
                            {getDayLabel(serviceYear, serviceMonth)[index]}
                          </option>
                        ),
                      )}
                    </select>

                    {errors.svc_day && (
                      <span className="tw-text-red-500 tw-font-semibold tw-text-sm">
                        This field is required
                      </span>
                    )}
                  </div>
                  <div>
                    <select
                      {...register("svc_year", { required: true })}
                      onChange={(e) => {
                        setValue("svc_year", e.target.value);
                        setServiceYear(e.target.value);
                      }}
                      value={serviceYear}
                      id="svc_year"
                      name="svc_year"
                      autocomplete="chrome-off"
                      className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-black tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 sm:tw-text-sm sm:tw-leading-6"
                    >
                      {MoreYears?.map((year, index) => (
                        <option key={`year${index}`} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                    {errors.svc_year && (
                      <span className="tw-text-red-500 tw-font-semibold tw-text-sm">
                        This field is required
                      </span>
                    )}
                  </div>
                </div>


                 <div className="tw-grid tw-grid-cols-2 tw-gap-x-2 tw-mb-3 md:tw-mb-0">
                  <div>
                  <div className="tw-col-span-3">
                    <label
                      htmlFor="time"
                      className="tw-block tw-text-sm tw-font-medum tw-leading-6 tw-text-black tw-mb-2"
                    >
                      Pick Up Time:
                    </label>
                  </div>

                    <select
                      value={puTime}
                      {...register("pickup_time", { required: true })}
                      onChange={(e) => {
                        setValue("pickup_time", e.target.value);
                        setPutime(e.target.value);
                      }}
                      id="pickup_time"
                      name="pickup_time"
                      autocomplete="chrome-off"
                      className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-black tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 sm:tw-text-sm sm:tw-leading-6"
                    >
                      <option value="">Select Time</option>
                      {timeslots?.map((timeslot) => (
                        <option key={timeslot.name} value={timeslot.value}>
                          {timeslot.name}
                        </option>
                      ))}
                    </select>
                    {errors.pickup_time && (
                      <span className="tw-text-red-500 tw-font-semibold tw-text-sm">
                        This field is required
                      </span>
                    )}
                  </div>

                  <div>
                    <label
                      htmlFor="date"
                      className="tw-block tw-text-sm tw-font-medum tw-font-medum tw-leading-6 tw-text-black tw-mb-2"
                    >
                      Hours Needed:
                    </label>

                    <select
                      {...register("hours_needed", {
                        required: true,
                        minLength: 1,
                      })}
                      onChange={(e) => {
                        setValue("hours_needed", e.target.value);
                        setHrs(e.target.value);
                      }}
                      value={hrs}
                      id="hours_needed"
                      name="hours_needed"
                      autocomplete="chrome-off"
                      className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-black tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 sm:tw-text-sm sm:tw-leading-6"
                    >
                      <option value="">Select Hours</option>
                       {range(3, 12, +1)?.map((item) => (
                          <option value={item} key={item}>
                            {item} Hours
                          </option>
                        ))}
                    <option value="More Than 12 Hours">More Than 12 Hours</option>
                    <option value="One Way Transfer">One Way Transfer</option>
                    <option value="Multiple Day Usage">Multiple Day Usage</option>
                    </select>

                    {errors.hours_needed && (
                      <span className="tw-text-red-500 tw-font-semibold tw-text-sm">
                        This field is required
                      </span>
                    )}
                  </div>
                </div>

                </div>
               



                <div className="md:tw-grid tw-grid-cols-2 tw-gap-x-4 tw-mb-3">
                  <div class="tw-mb-2 md:tw-mb-0">
                    <label
                      htmlFor="mobile"
                      className="tw-block tw-text-sm tw-font-medum tw-leading-6 tw-text-black tw-mb-2"
                    >
                      Mobile Number <span className="tw-text-xs">(Info never shared)</span>
                    </label>
                    <div>
                      <input
                        className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-black tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 sm:tw-text-sm sm:tw-leading-6"
                        {...register("mobile_number", {maxLength: 25})}
                        placeholder="Mobile Number"
                      />
                     
                    
                     
                    </div>
                       <span className="tw-block tw-text-xs tw-font-medum tw-leading-6 tw-text-black tw-mt-2">
                          *Text message with live pricing and pics 
                        </span>
                  </div>

                  <div className="tw-flex-col tw-h-full tw-justify-between">
                    <label
                      htmlFor="email"
                      className="tw-block tw-text-sm tw-font-medum tw-leading-6 tw-text-black tw-mb-2"
                    >
                      Email Address

                    </label>
                    <div>
                      <input
                        className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-black tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 sm:tw-text-sm sm:tw-leading-6"
                        {...register("email_address", { maxLength: 100, required: true })}
                        placeholder="Email Address"
                      />
                      {errors.email_address && (
                        <span className="tw-text-red-500 tw-font-semibold tw-text-sm">
                          This field is required
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                 
                <div className="tw-col-span-full tw-mb-3 tw-mt-3">
                  <label
                    htmlFor="details"
                    className="tw-block tw-text-xs tw-font-medum tw-leading-6 tw-text-black tw-mb-2"
                  >
                    Please include a basic itinerary for your trip and any specific vehicle of interest to better match your request. More information would be greatly appreciated.
                  </label>
                  <div>
                    <textarea
                      id="details"
                      name="details"
                      rows={3}
                      className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-black tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 sm:tw-text-sm sm:tw-leading-6"
                      defaultValue={""}
                      placeholder="Basic itinerary & Trip Details.."
                      {...register("trip_details", {
                        maxLength: 500
                      })}

                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <button
              type="submit"
              onclick={() => {
                event.target.disabled = true;
              }}
              className="tw-block tw-rounded-md tw-bg-green-600 tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-green-500 focus-visible:tw-outline tw-w-3/4 md:tw-w-1/3 lg:tw-w-96 tw-mx-auto focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-green-600"
              disabled={disabled}
            >
              {disabled ? (
                <span className="tw-flex tw-justify-center">
                  <svg
                    class="tw-animate-spin -tw-ml-1 tw-mr-3 tw-h-5 tw-w-5 tw-text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="tw-opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Sending...
                </span>
              ) : (
                "Get Instant Pricing & Availability"
              )}
            </button>
            <p className="tw-mt-1 tw-text-xs tw-text-center tw-w-full tw-mx-auto tw-leading-6 tw-text-gray-600">
              I agree to the Privacy Policy and <a className="tw-font-semibold" href="https://www.price4limo.com/terms_of_use.html" target="_blank">Terms and Conditions</a> and allow
              communication via email, text message, and phone calls.{" "}
            </p>
          </div>
        </form>
      </FormProvider>
    }
    </div>
  );
}
